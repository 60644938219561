<div class="py-6">
  <!-- Page title -->
  <h2>Agent</h2>

  <div
    class="rounded-md bg-black/30 backdrop-blur-[64px] shadow-lg ring-1 ring-white/10">
    <p-tabs [value]="tabs[0].route" scrollable="true">
      <p-tablist>
        @for (tab of tabs; track tab.route) {
          <p-tab [value]="tab.route" [routerLink]="tab.route">
            <span>{{ tab.label }}</span>
          </p-tab>
        }
      </p-tablist>
    </p-tabs>
    <router-outlet></router-outlet>
  </div>
</div>
