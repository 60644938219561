<div
  class="h-20 w-full px-4 grid md:grid-cols-[auto_1fr] grid-cols-[auto_1fr_auto] md:gap-8 gap-0 items-center rounded-md">
  <div class="">
    <a
      routerLink="/"
      routerLinkActive=""
      [routerLinkActiveOptions]="{ exact: true }">
      <img
        src="images/logo/transparent_animated.webp"
        alt="Logo BSFR"
        width="48"
        height="48" />
    </a>
  </div>
  <div
    class="md:flex md:gap-2 md:justify-end md:whitespace-nowrap md:items-center text-[var(--text-color)] hidden">
    @for (menuItem of menuItems; track menuItem.label) {
      <p-button
        [label]="menuItem.label"
        [text]="!rla.isActive"
        [routerLink]="menuItem.path"
        routerLinkActive=""
        #rla="routerLinkActive" />
    }
    <div *ngIf="isLogged$ | async as logged; else userNotLogged">
      <div *ngIf="user$ | async as user; else userLoading">
        <p-avatar
          [image]="user.avatarURL"
          size="large"
          shape="circle"
          class="ms-3 cursor-pointer"
          (click)="avatarMenu.toggle($event)" />
        <p-menu
          #avatarMenu
          [model]="user.isAdmin ? adminMenuItems : userMenuItems"
          [popup]="true">
          <ng-template #item let-item>
            <ng-container *ngIf="item.route; else withoutRoute">
              <a
                [routerLink]="item.route"
                routerLinkActive="!text-[var(--primary-color)]"
                class="p-menu-item-link">
                <span [class]="item.icon"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </ng-container>
            <ng-template #withoutRoute>
              <a
                [href]="item.url"
                [target]="item.target"
                class="p-menu-item-link">
                <span [class]="item.icon"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </ng-template>
          </ng-template>
        </p-menu>
      </div>
      <ng-template #userLoading>
        <p-button [loading]="true" disabled="true" [text]="true" size="large" />
      </ng-template>
    </div>
    <ng-template #userNotLogged>
      <p-button
        icon="pi pi-user"
        [text]="true"
        size="large"
        (click)="login()" />
    </ng-template>
  </div>
  <div class="block md:hidden justify-self-end">
    <p-button
      icon="pi pi-bars"
      [text]="true"
      size="large"
      (onClick)="setIsOpen(!isOpen)" />
  </div>
</div>
<div
  [classList]="
    'fixed z-10 top-0 left-0 bottom-0 right-0 bg-black/50 md:hidden transition-opacity duration-300 ' +
    (isOpen
      ? 'opacity-100 pointer-events-auto'
      : 'opacity-0 pointer-events-none')
  "
  (click)="setIsOpen(false)"></div>
<div
  [classList]="
    'h-full w-[300px] max-w-[80%] fixed z-20 top-0 p-4 md:hidden grid grid-rows-[100px_1fr_auto] grid-cols-1 bg-black transition-all duration-300 overflow-y-scroll ' +
    (isOpen ? 'left-0' : '-left-[300px]')
  ">
  <div class="place-self-center">
    <img
      src="images/logo/transparent_animated.webp"
      alt="Logo BSFR"
      width="64"
      height="64" />
  </div>
  <ul class="list-none p-0 m-0">
    <li *ngFor="let menuItem of menuItems">
      <p-button
        styleClass="w-full p-4 justify-start"
        [text]="!rla.isActive"
        [routerLink]="menuItem.path"
        routerLinkActive=""
        [routerLinkActiveOptions]="{ exact: true }"
        #rla="routerLinkActive"
        (click)="setIsOpen(false)">
        <span
          [innerHTML]="menuItem.icon | svg: '16'"
          class="me-3 flex items-center"></span>
        <span>{{ menuItem.label }}</span>
      </p-button>
    </li>
  </ul>
  <ng-container *ngIf="user$ | async as user">
    <ul class="list-none p-0 m-0" *ngIf="user.isAdmin">
      <li *ngFor="let menuItem of mobileAdminMenuItems">
        <p-button
          styleClass="w-full p-4 justify-start"
          [text]="!rla.isActive"
          [routerLink]="menuItem.path"
          routerLinkActive=""
          [routerLinkActiveOptions]="{ exact: true }"
          #rla="routerLinkActive"
          (click)="setIsOpen(false)">
          <span [class]="menuItem.icon" class="me-3"></span>
          <span>{{ menuItem.label }}</span>
        </p-button>
      </li>
    </ul>
  </ng-container>
  <div>
    <p-divider />
    <div
      class="grid grid-cols-[1fr_auto] gap-2"
      *ngIf="user$ | async as user; else userNotLoggedMobile">
      <p-button
        [text]="!rla.isActive"
        styleClass="w-full p-4 justify-start"
        routerLink="/profil"
        routerLinkActive=""
        [routerLinkActiveOptions]="{ exact: true }"
        #rla="routerLinkActive"
        (click)="setIsOpen(false)">
        <p-avatar
          [image]="user.avatarURL"
          styleClass="me-4 flex items-center"
          size="large"
          shape="circle" />
        <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
          user.username
        }}</span>
      </p-button>
      <p-button [text]="true" styleClass="w-full h-full p-4" (click)="logout()">
        <span class="pi pi-sign-out"></span>
      </p-button>
    </div>
    <ng-template #userNotLoggedMobile>
      <p-button
        [text]="true"
        styleClass="w-full p-4 flex justify-between"
        (click)="login()">
        <span>Connexion</span>
        <span class="pi pi-sign-in"></span>
      </p-button>
    </ng-template>
  </div>
</div>
