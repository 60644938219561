import { animation, style, animate, trigger, transition, useAnimation } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ViewChild, ContentChildren, NgModule } from '@angular/core';
import { blockBodyScroll, appendChild } from '@primeuix/utils';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import * as i2 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { TimesIcon } from 'primeng/icons';
import { ZIndexUtils } from 'primeng/utils';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `

.p-drawer {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    transition: transform 0.3s;
    background: ${dt('drawer.background')};
    color: ${dt('drawer.color')};
    border: 1px solid ${dt('drawer.border.color')};
    box-shadow: ${dt('drawer.shadow')};
}

.p-drawer-content {
    overflow-y: auto;
    flex-grow: 1;
    padding: ${dt('drawer.content.padding')};
}

.p-drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: ${dt('drawer.header.padding')};
}

.p-drawer-footer {
    padding: ${dt('drawer.header.padding')};
}

.p-drawer-title {
    font-weight: ${dt('drawer.title.font.weight')};
    font-size: ${dt('drawer.title.font.size')};
}

.p-drawer-full .p-drawer {
    transition: none;
    transform: none;
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100%;
    top: 0px !important;
    left: 0px !important;
    border-width: 1px;
}

/* PrimeVue animations

.p-drawer-left .p-drawer-enter-from,
.p-drawer-left .p-drawer-leave-to {
    transform: translateX(-100%);
}

.p-drawer-right .p-drawer-enter-from,
.p-drawer-right .p-drawer-leave-to {
    transform: translateX(100%);
}

.p-drawer-top .p-drawer-enter-from,
.p-drawer-top .p-drawer-leave-to {
    transform: translateY(-100%);
}

.p-drawer-bottom .p-drawer-enter-from,
.p-drawer-bottom .p-drawer-leave-to {
    transform: translateY(100%);
}

.p-drawer-full .p-drawer-enter-from,
.p-drawer-full .p-drawer-leave-to {
    opacity: 0;
}

.p-drawer-full .p-drawer-enter-active,
.p-drawer-full .p-drawer-leave-active {
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
*/

.p-drawer-left .p-drawer {
    align-self: start;
    width: 20rem;
    height: 100%;
    border-right-width: 1px;
}

.p-drawer-right .p-drawer {
    align-self: end;
    width: 20rem;
    height: 100%;
    border-left-width: 1px;
}

.p-drawer-top .p-drawer {

    height: 10rem;
    width: 100%;
    border-bottom-width: 1px;
}

.p-drawer-bottom .p-drawer {
    height: 10rem;
    width: 100%;
    border-top-width: 1px;
}

.p-drawer-left .p-drawer-content,
.p-drawer-right .p-drawer-content,
.p-drawer-top .p-drawer-content,
.p-drawer-bottom .p-drawer-content {
    width: 100%;
    height: 100%;
}

.p-drawer-open {
    display: flex;
}

.p-drawer-top {
    justify-content: flex-start;
}

.p-drawer-bottom {
    justify-content: flex-end;
}
`;
const inlineStyles = {
  mask: ({
    instance
  }) => ({
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: instance.position === 'top' ? 'flex-start' : instance.position === 'bottom' ? 'flex-end' : 'center'
  })
};
const classes = {
  mask: ({
    instance
  }) => ({
    'p-drawer-mask': true,
    'p-overlay-mask p-overlay-mask-enter': instance.modal,
    'p-drawer-open': instance.containerVisible,
    'p-drawer-full': instance.fullScreen,
    [`p-drawer-${instance.position}`]: !!instance.position
  }),
  root: ({
    instance
  }) => ({
    'p-drawer p-component': true,
    'p-drawer-full': instance.fullScreen
  }),
  header: 'p-drawer-header',
  title: 'p-drawer-title',
  pcCloseButton: 'p-drawer-close-button',
  content: 'p-drawer-content',
  footer: 'p-drawer-footer'
};
class DrawerStyle extends BaseStyle {
  name = 'drawer';
  theme = theme;
  classes = classes;
  inlineStyles = inlineStyles;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵDrawerStyle_BaseFactory;
    return function DrawerStyle_Factory(__ngFactoryType__) {
      return (ɵDrawerStyle_BaseFactory || (ɵDrawerStyle_BaseFactory = i0.ɵɵgetInheritedFactory(DrawerStyle)))(__ngFactoryType__ || DrawerStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: DrawerStyle,
    factory: DrawerStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DrawerStyle, [{
    type: Injectable
  }], null, null);
})();
const _c0 = ["maskRef"];
const _c1 = ["container"];
const _c2 = ["closeButton"];
const _c3 = ["*"];
const _c4 = (a0, a1) => ({
  transform: a0,
  transition: a1
});
const _c5 = a0 => ({
  value: "visible",
  params: a0
});
function Sidebar_div_0_Conditional_3_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Sidebar_div_0_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Sidebar_div_0_Conditional_3_ng_container_0_Template, 1, 0, "ng-container", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headlessTemplate || ctx_r1._headlessTemplate);
  }
}
function Sidebar_div_0_Conditional_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Sidebar_div_0_Conditional_4_p_button_2_TimesIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "closeicon");
  }
}
function Sidebar_div_0_Conditional_4_p_button_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function Sidebar_div_0_Conditional_4_p_button_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Sidebar_div_0_Conditional_4_p_button_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Sidebar_div_0_Conditional_4_p_button_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtemplate(1, Sidebar_div_0_Conditional_4_p_button_2_span_2_1_Template, 1, 0, null, 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵattribute("data-pc-section", "closeicon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.closeIconTemplate || ctx_r1._closeIconTemplate);
  }
}
function Sidebar_div_0_Conditional_4_p_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 8);
    i0.ɵɵlistener("onClick", function Sidebar_div_0_Conditional_4_p_button_2_Template_p_button_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.close($event));
    })("keydown.enter", function Sidebar_div_0_Conditional_4_p_button_2_Template_p_button_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.close($event));
    });
    i0.ɵɵtemplate(1, Sidebar_div_0_Conditional_4_p_button_2_TimesIcon_1_Template, 1, 1, "TimesIcon", 7)(2, Sidebar_div_0_Conditional_4_p_button_2_span_2_Template, 2, 2, "span", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("closeButton"))("buttonProps", ctx_r1.closeButtonProps)("ariaLabel", ctx_r1.ariaCloseLabel);
    i0.ɵɵattribute("data-pc-section", "closebutton")("data-pc-group-section", "iconcontainer");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.closeIconTemplate && !ctx_r1._closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.closeIconTemplate || ctx_r1._closeIconTemplate);
  }
}
function Sidebar_div_0_Conditional_4_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Sidebar_div_0_Conditional_4_ng_container_6_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Sidebar_div_0_Conditional_4_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtemplate(2, Sidebar_div_0_Conditional_4_ng_container_6_ng_container_2_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", ctx_r1.cx("footer"));
    i0.ɵɵattribute("data-pc-section", "footer");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.footerTemplate || ctx_r1._footerTemplate);
  }
}
function Sidebar_div_0_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, Sidebar_div_0_Conditional_4_ng_container_1_Template, 1, 0, "ng-container", 4)(2, Sidebar_div_0_Conditional_4_p_button_2_Template, 3, 7, "p-button", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵprojection(4);
    i0.ɵɵtemplate(5, Sidebar_div_0_Conditional_4_ng_container_5_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, Sidebar_div_0_Conditional_4_ng_container_6_Template, 3, 3, "ng-container", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("header"));
    i0.ɵɵattribute("data-pc-section", "header");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headerTemplate || ctx_r1._headerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showCloseIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", ctx_r1.cx("content"));
    i0.ɵɵattribute("data-pc-section", "content");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTemplate || ctx_r1._contentTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.footerTemplate || ctx_r1._footerTemplate);
  }
}
function Sidebar_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2, 0);
    i0.ɵɵlistener("@panelState.start", function Sidebar_div_0_Template_div_animation_panelState_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@panelState.done", function Sidebar_div_0_Template_div_animation_panelState_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    })("click", function Sidebar_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.maskClickListener($event));
    });
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵlistener("keydown", function Sidebar_div_0_Template_div_keydown_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onKeyDown($event));
    });
    i0.ɵɵtemplate(3, Sidebar_div_0_Conditional_3_Template, 1, 1, "ng-container")(4, Sidebar_div_0_Conditional_4_Template, 7, 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r1.maskStyle);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("mask"))("ngStyle", ctx_r1.sx("mask"))("@panelState", i0.ɵɵpureFunction1(15, _c5, i0.ɵɵpureFunction2(12, _c4, ctx_r1.transformOptions, ctx_r1.transitionOptions)));
    i0.ɵɵattribute("data-pc-name", "mask")("data-pc-section", "mask");
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.styleClass);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("root"));
    i0.ɵɵattribute("data-pc-section", "root");
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.headlessTemplate || ctx_r1._headlessTemplate ? 3 : 4);
  }
}
const showAnimation = animation([style({
  transform: '{{transform}}',
  opacity: 0
}), animate('{{transition}}')]);
const hideAnimation = animation([animate('{{transition}}', style({
  transform: '{{transform}}',
  opacity: 0
}))]);
/**
 * Sidebar is a panel component displayed as an overlay at the edges of the screen.
 * @group Components
 */
class Sidebar extends BaseComponent {
  /**
   *  Target element to attach the dialog, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo = 'body';
  /**
   * Whether to block scrolling of the document when sidebar is active.
   * @group Props
   */
  blockScroll = false;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Aria label of the close icon.
   * @group Props
   */
  ariaCloseLabel;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Whether an overlay mask is displayed behind the sidebar.
   * @group Props
   */
  modal = true;
  /**
   * Used to pass all properties of the ButtonProps to the Button component.
   * @group Props
   */
  closeButtonProps;
  /**
   * Whether to dismiss sidebar on click of the mask.
   * @group Props
   */
  dismissible = true;
  /**
   * Whether to display the close icon.
   * @group Props
   */
  showCloseIcon = true;
  /**
   * Specifies if pressing escape key should hide the sidebar.
   * @group Props
   */
  closeOnEscape = true;
  /**
   * Transition options of the animation.
   * @group Props
   */
  transitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Specifies the visibility of the dialog.
   * @group Props
   */
  get visible() {
    return this._visible;
  }
  set visible(val) {
    this._visible = val;
  }
  /**
   * Specifies the position of the sidebar, valid values are "left", "right", "bottom" and "top".
   * @group Props
   */
  get position() {
    return this._position;
  }
  set position(value) {
    this._position = value;
    switch (value) {
      case 'left':
        this.transformOptions = 'translate3d(-100%, 0px, 0px)';
        break;
      case 'right':
        this.transformOptions = 'translate3d(100%, 0px, 0px)';
        break;
      case 'bottom':
        this.transformOptions = 'translate3d(0px, 100%, 0px)';
        break;
      case 'top':
        this.transformOptions = 'translate3d(0px, -100%, 0px)';
        break;
    }
  }
  /**
   * Adds a close icon to the header to hide the dialog.
   * @group Props
   */
  get fullScreen() {
    return this._fullScreen;
  }
  set fullScreen(value) {
    this._fullScreen = value;
    if (value) this.transformOptions = 'none';
  }
  maskStyle;
  /**
   * Callback to invoke when dialog is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when dialog is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * Callback to invoke when dialog visibility is changed.
   * @param {boolean} value - Visible value.
   * @group Emits
   */
  visibleChange = new EventEmitter();
  maskRef;
  containerViewChild;
  closeButtonViewChild;
  initialized;
  _visible;
  _position = 'left';
  _fullScreen = false;
  container;
  transformOptions = 'translate3d(-100%, 0px, 0px)';
  mask;
  documentEscapeListener;
  _componentStyle = inject(DrawerStyle);
  /**
   * Header template.
   * @group Props
   */
  headerTemplate;
  /**
   * Footer template.
   * @group Props
   */
  footerTemplate;
  /**
   *
   * Close icon template.
   * @group Props
   */
  closeIconTemplate;
  /**
   * Headless template.
   * @group Props
   */
  headlessTemplate;
  /**
   * Headless template.
   * @group Props
   */
  contentTemplate;
  templates;
  _headerTemplate;
  _footerTemplate;
  _contentTemplate;
  _closeIconTemplate;
  _headlessTemplate;
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initialized = true;
  }
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    const key = Object.keys(changes).find(k => k.includes('Template'));
    if (key) {
      this[`_${key}`] = changes[key].currentValue;
    }
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this._contentTemplate = item.template;
          break;
        case 'header':
          this._headerTemplate = item.template;
          break;
        case 'footer':
          this._footerTemplate = item.template;
          break;
        case 'closeicon':
          this._closeIconTemplate = item.template;
          break;
        case 'headless':
          this._headlessTemplate = item.template;
          break;
        default:
          this._contentTemplate = item.template;
          break;
      }
    });
  }
  onKeyDown(event) {
    if (event.code === 'Escape') {
      this.hide(false);
    }
  }
  show() {
    if (this.autoZIndex) {
      ZIndexUtils.set('modal', this.container, this.baseZIndex || this.config.zIndex.modal);
    }
    this.onShow.emit({});
    this.visibleChange.emit(true);
  }
  hide(emit = true) {
    if (emit) {
      this.onHide.emit({});
    }
  }
  close(event) {
    this.hide();
    this.visibleChange.emit(false);
    event.preventDefault();
  }
  maskClickListener(event) {
    if (this.dismissible) {
      this.close(event);
    }
    if (this.blockScroll) {
      blockBodyScroll();
    }
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.appendContainer();
        this.show();
        if (this.closeOnEscape) {
          this.bindDocumentEscapeListener();
        }
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        this.hide(false);
        ZIndexUtils.clear(this.container);
        this.unbindGlobalListeners();
        break;
    }
  }
  appendContainer() {
    if (this.appendTo) {
      return this.appendTo === 'body' ? this.renderer.appendChild(this.document.body, this.container) : appendChild(this.appendTo, this.container);
    }
  }
  bindDocumentEscapeListener() {
    const documentTarget = this.el ? this.el.nativeElement.ownerDocument : this.document;
    this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', event => {
      if (event.which == 27) {
        if (parseInt(this.container.style.zIndex) === ZIndexUtils.get(this.container)) {
          this.close(event);
        }
      }
    });
  }
  unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }
  unbindGlobalListeners() {
    this.unbindDocumentEscapeListener();
  }
  ngOnDestroy() {
    this.initialized = false;
    if (this.appendTo && this.container) {
      this.renderer.appendChild(this.el.nativeElement, this.container);
    }
    if (this.container && this.autoZIndex) {
      ZIndexUtils.clear(this.container);
    }
    this.container = null;
    this.unbindGlobalListeners();
    super.ngOnDestroy();
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵSidebar_BaseFactory;
    return function Sidebar_Factory(__ngFactoryType__) {
      return (ɵSidebar_BaseFactory || (ɵSidebar_BaseFactory = i0.ɵɵgetInheritedFactory(Sidebar)))(__ngFactoryType__ || Sidebar);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Sidebar,
    selectors: [["p-sidebar"]],
    contentQueries: function Sidebar_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Sidebar_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c2, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.maskRef = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.closeButtonViewChild = _t.first);
      }
    },
    inputs: {
      appendTo: "appendTo",
      blockScroll: [2, "blockScroll", "blockScroll", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      ariaCloseLabel: "ariaCloseLabel",
      autoZIndex: [2, "autoZIndex", "autoZIndex", booleanAttribute],
      baseZIndex: [2, "baseZIndex", "baseZIndex", numberAttribute],
      modal: [2, "modal", "modal", booleanAttribute],
      closeButtonProps: "closeButtonProps",
      dismissible: [2, "dismissible", "dismissible", booleanAttribute],
      showCloseIcon: [2, "showCloseIcon", "showCloseIcon", booleanAttribute],
      closeOnEscape: [2, "closeOnEscape", "closeOnEscape", booleanAttribute],
      transitionOptions: "transitionOptions",
      visible: "visible",
      position: "position",
      fullScreen: "fullScreen",
      maskStyle: "maskStyle",
      headerTemplate: "headerTemplate",
      footerTemplate: "footerTemplate",
      closeIconTemplate: "closeIconTemplate",
      headlessTemplate: "headlessTemplate",
      contentTemplate: "contentTemplate"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide",
      visibleChange: "visibleChange"
    },
    features: [i0.ɵɵProvidersFeature([DrawerStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
    ngContentSelectors: _c3,
    decls: 1,
    vars: 1,
    consts: [["maskRef", ""], [3, "ngClass", "ngStyle", "style", "click", 4, "ngIf"], [3, "click", "ngClass", "ngStyle"], [3, "keydown", "ngClass"], [4, "ngTemplateOutlet"], [3, "ngClass"], [3, "ngClass", "buttonProps", "ariaLabel", "onClick", "keydown.enter", 4, "ngIf"], [4, "ngIf"], [3, "onClick", "keydown.enter", "ngClass", "buttonProps", "ariaLabel"], ["class", "p-sidebar-close-icon", 4, "ngIf"], [1, "p-sidebar-close-icon"]],
    template: function Sidebar_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, Sidebar_div_0_Template, 5, 17, "div", 1);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, SharedModule, TimesIcon, ButtonModule, i2.Button],
    encapsulation: 2,
    data: {
      animation: [trigger('panelState', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Sidebar, [{
    type: Component,
    args: [{
      selector: 'p-sidebar',
      standalone: true,
      imports: [CommonModule, SharedModule, TimesIcon, ButtonModule],
      template: `
        <div
            #maskRef
            *ngIf="visible"
            [ngClass]="cx('mask')"
            [ngStyle]="sx('mask')"
            [style]="maskStyle"
            [@panelState]="{ value: 'visible', params: { transform: transformOptions, transition: transitionOptions } }"
            (@panelState.start)="onAnimationStart($event)"
            (@panelState.done)="onAnimationEnd($event)"
            [attr.data-pc-name]="'mask'"
            [attr.data-pc-section]="'mask'"
            (click)="maskClickListener($event)"
        >
            <div [ngClass]="cx('root')" [class]="styleClass" [attr.data-pc-section]="'root'" (keydown)="onKeyDown($event)">
                @if (headlessTemplate || _headlessTemplate) {
                    <ng-container *ngTemplateOutlet="headlessTemplate || _headlessTemplate"></ng-container>
                } @else {
                    <div [ngClass]="cx('header')" [attr.data-pc-section]="'header'">
                        <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate"></ng-container>
                        <p-button
                            *ngIf="showCloseIcon"
                            [ngClass]="cx('closeButton')"
                            (onClick)="close($event)"
                            (keydown.enter)="close($event)"
                            [buttonProps]="closeButtonProps"
                            [ariaLabel]="ariaCloseLabel"
                            [attr.data-pc-section]="'closebutton'"
                            [attr.data-pc-group-section]="'iconcontainer'"
                        >
                            <TimesIcon *ngIf="!closeIconTemplate && !_closeIconTemplate" [attr.data-pc-section]="'closeicon'" />
                            <span *ngIf="closeIconTemplate || _closeIconTemplate" class="p-sidebar-close-icon" [attr.data-pc-section]="'closeicon'">
                                <ng-template *ngTemplateOutlet="closeIconTemplate || _closeIconTemplate"></ng-template>
                            </span>
                        </p-button>
                    </div>

                    <div [ngClass]="cx('content')" [attr.data-pc-section]="'content'">
                        <ng-content></ng-content>
                        <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                    </div>

                    <ng-container *ngIf="footerTemplate || _footerTemplate">
                        <div [ngClass]="cx('footer')" [attr.data-pc-section]="'footer'">
                            <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate"></ng-container>
                        </div>
                    </ng-container>
                }
            </div>
        </div>
    `,
      animations: [trigger('panelState', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [DrawerStyle]
    }]
  }], null, {
    appendTo: [{
      type: Input
    }],
    blockScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    ariaCloseLabel: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    baseZIndex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    modal: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    closeButtonProps: [{
      type: Input
    }],
    dismissible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showCloseIcon: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    closeOnEscape: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    transitionOptions: [{
      type: Input
    }],
    visible: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    fullScreen: [{
      type: Input
    }],
    maskStyle: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    visibleChange: [{
      type: Output
    }],
    maskRef: [{
      type: ViewChild,
      args: ['maskRef']
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    closeButtonViewChild: [{
      type: ViewChild,
      args: ['closeButton']
    }],
    headerTemplate: [{
      type: Input
    }],
    footerTemplate: [{
      type: Input
    }],
    closeIconTemplate: [{
      type: Input
    }],
    headlessTemplate: [{
      type: Input
    }],
    contentTemplate: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Sidebar, {
    className: "Sidebar",
    filePath: "sidebar.ts",
    lineNumber: 101
  });
})();
class SidebarModule {
  static ɵfac = function SidebarModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SidebarModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: SidebarModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Sidebar, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarModule, [{
    type: NgModule,
    args: [{
      imports: [Sidebar, SharedModule],
      exports: [Sidebar, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SidebarModule, {
    imports: [Sidebar, SharedModule],
    exports: [Sidebar, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DrawerStyle, Sidebar, SidebarModule };
