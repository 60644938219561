<p-card styleClass="bg-transparent">
  <ng-container *ngIf="loading">
    <div class="grid items-center justify-center">
      <p-progressSpinner ariaLabel="chargement" />
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div
      class="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
      <ng-container *ngFor="let roleCategory of roles">
        <div>
          <div class="mb-3 font-bold">
            {{ roleCategory.categoryName }}
          </div>
          <div class="grid gap-y-3">
            <div *ngFor="let role of roleCategory.roles">
              <div class="flex items-center">
                <p-checkbox
                  [(ngModel)]="selected[role.name]"
                  [binary]="true"
                  (onChange)="
                    rolesUpdated($event, roleCategory.categoryName, role)
                  " />
                <label class="ml-2">{{ role.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex mt-6">
      <p-button
        label="Sauvegarder"
        severity="success"
        class="ms-auto"
        icon="pi pi-save"
        [disabled]="!canSave"
        [loading]="saving"
        (onClick)="save()" />
    </div>
  </ng-container>
</p-card>
