<div class="py-6">
  <!-- Page title -->
  <h2>Accueil</h2>

  <p-card
    header="Actualités"
    styleClass="rounded-md bg-black/30 backdrop-blur-[64px] shadow-lg ring-1 ring-white/10">
    <div *ngFor="let n of news; let isLast = last">
      <div
        class="md:grid md:grid-cols-[auto_auto] md:gap-x-4 md:items-center flex flex-col-reverse">
        <h2>{{ n.title }}</h2>
        <p-chip [label]="n.date" class="md:justify-self-end" />
      </div>

      <span [innerHTML]="n.content | trustHTML"></span>

      <div *ngFor="let file of n.files">
        <a pButton pRipple (click)="comming()">
          <span
            [innerHTML]="icons.download | svg"
            class="flex items-center"></span>
          {{ file.name }}
        </a>
      </div>

      <p-divider type="dashed" *ngIf="!isLast" />
    </div>
  </p-card>
</div>
